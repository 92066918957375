import React from "react";
import { Link } from "gatsby";
import {ArrowRightOutlined} from '@ant-design/icons';
import {Card} from 'antd';

import suspendedImg from '../../images/services/Suspended Liceneses.png';
import ServiceTemplateCascade from "./ServiceTemplateCascade";
import Routes from "../../constant/routes";

const Items = [
  {key: 1, title: 'How much does a suspended license lawyer cost?', preview: <div>
    <div className="BodyGreyLeft mb16">The cost for a suspended license lawyer will vary greatly, depending upon the lawyer's practice and what it required to get your license reinstated. The range is likely between
    $200 and $600.<strong> TicketTamer can get your license reinstated for just $300 in most cases, and you won’t have to go to court.</strong>
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    The cost for a suspended license lawyer will vary greatly, depending upon the lawyer's practice and what it required to get your license reinstated. The range is likely between
$200 and $600.
    </div>
    <div className="BodyGreyLeft mb16">
    <strong>TicketTamer will fix your suspended license for $300. This includes a $100 assessment fee to determine what it will take to get your license reinstated, a strategy session for getting you back on the road, guiding you through the process and filing your compliance documentation with the proper court.</strong>
    </div>
    <div className="BodyGreyLeft mb16">
    If your license is not eligible for reinstatement, we can also get you back on the road legally with a Limited Driving Privilege that will allow you to go back and forth to work. This will help you avoid getting more tickets for driving with a suspended license while you are waiting to reinstate your license.
    </div>
    
  </div>},
  {key: 2, title: 'What happens if you get pulled over with a suspended license in Missouri?', preview: <div>
    <div className="BodyGreyLeft mb16">If you get caught driving with a suspended license in a point state like Missouri, you will get a ticket for driving with a suspended license. This is a serious ticket. <strong>TicketTamer can get your license reinstated for just $300 in most cases, and you won’t have to go to court. </strong>
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    If you get caught driving with a suspended license in a point state like Missouri, you will get a ticket for driving with a suspended license. This is a serious ticket.
    </div>
    {/* <a href="https://dor.mo.gov/faq/driver-license/tickets-points.html" className="Link">Click here for more on license suspension and revocation.<ArrowRightOutlined /></a> */}
    
    <div className="BodyGreyLeft mb16 mt16">
    If you just plead guilty and pay the fine, the fine will likely be between $50-$60 plus court costs between $25 and $75.
    </div>
    {/* <a href="https://www.courts.mo.gov/page.jsp?id=310" className="Link">Click here for state and local court fine information.<ArrowRightOutlined /></a> */}
    
    <div className="BodyGreyLeft mb16">
    But you will also get 12 points on your record and your license will be automatically suspended for 12 months - for each ticket you get for driving with a suspended license.
    </div>
    {/* <a href="https://dor.mo.gov/faq/driver-license/tickets-points.html" className="Link">Click here for Missouri's point system.<ArrowRightOutlined /></a> */}
    
    <div className="BodyGreyLeft mb16">
    You will also have to a lot more for insurance, and may have a hard time even getting it-­ for years. With most traffic tickets, insurance companies use tickets and points to increase your rates.
    </div>
    {/* <a href="https://www.nerdwallet.com/article/insurance/auto-insurance-rates-after-speeding-ticket" className="Link">Click here for more information on tickets and insurance.<ArrowRightOutlined /></a> */}
    
    <div className="BodyGreyLeft mb16">
    If the ticket is for driving while suspended, you will get so many points that many insurers simply will not cover you, or will cover you through a state-sponsored assigned risk pool.
    </div>
    {/* <a href="https://www.nerdwallet.com/article/insurance/auto-insurance-rates-after-speeding-ticket" className="Link">Click here for more information on tickets and insurance.<ArrowRightOutlined /></a> */}
    
    <div className="BodyGreyLeft mb16">
    <strong>TicketTamer will help you avoid this by fixing your suspended license for just $300. This includes a $100 assessment fee to determine what it will take to get your license reinstated, a strategy session for getting you back on the road, guiding you through the process and filing your compliance documentation with the proper court.</strong>
    </div>

    <div className="BodyGreyLeft mb16 mt16">
    If your license is not eligible for reinstatement, we can also get you back on the road legally with a Limited Driving Privilege that will allow you to go back and forth to work This will help you avoid getting more tickets for driving with a suspended license while you are waiting to reinstate your license.
    </div>
</div>},
  {key: 3, title: 'Can you buy a car with a suspended license in Missouri?', preview: <div>
    <div className="BodyGreyLeft mb16">No. You will need to get your license reinstated before you will be able to buy a car in Missouri. <strong>TicketTamer can get  your license reinstated for just $300 in most cases, and you won’t have to go to court.</strong>
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    No. You will need to get your license reinstated before you will be able to buy a car in Missouri.
    <br/>
    <br/>
    The reason is that Missouri requires you to have insurance if you own a car, and you generally can't get insurance if your license is suspended.
    <br/>
    <br/>

    <strong>TicketTamer will help you avoid this by fixing your suspended license for just $300. This includes a $100 assessment  fee to  determine what it will take to get your license reinstated, a strategy session for getting you back on the road, guiding you through the process and filing your compliance documentation with the proper court.</strong>
    <br/>
    <br/>

    {/* <a href="https://dor.mo.gov/faq/driver-license/tickets-points.html" className="Link">Click here for more on license suspension and revocation.<ArrowRightOutlined /></a>
    <br/>
    <br/> */}

    If your license is not eligible for reinstatement, we can also get you back on the road legally with a Limited Driving Privilege that will allow you to go back and forth to work. This will help you avoid getting more tickets for driving with a suspended license while you are waiting to reinstate your license.

    </div>
    </div>},
  {key: 4, title: 'What you absolutely need to know before you pay your suspended license ticket.', preview: <div>
    <div className="BodyGreyLeft mb16">There are five things you need to know before you just pay your ticket. Compare and see why 40 percent of TicketTamer cases are referrals or returning satisfied clients. 
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    There are five things you need to know before you just pay your ticket.
    <br/>
    <br/>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    You'll be pleading guilty to the violation, and it will be on your record forever.
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    You'll get 12 points on your driving record in point system states, including Missouri
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    your license will be automatically suspended for 12 months - for each ticket you get for driving with a suspended license. With a ticket for driving while suspended, you will get so many points that many insurers simply will not cover you, or will only cover you through a state­ sponsored assir:ned risk pool. 
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    <strong>TicketTamer will help you avoid this by fixing your suspended license for just $300. This includes a $100 assessment fee to determine what it will take to get your license reinstated, a strategy session for getting you back on the road, guiding you through the process and filing your compliance documentation  with the proper court.</strong>
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    If your license is not eligible for reinstatement, we can also get you back on the road legally with a Limited Driving Privilege that will allow you to go back and forth to work. This will help you avoid getting more tickets for driving with a suspended license while you are waiting to reinstate your license.
    </Card>
    </div>
    <div className="BodyGreyLeft mb16">
    TicketTamer charges just $75 for a basic speeding ticket of 10-14 miles per hour over the speed limit. <Link to={Routes.PRICING}>Other charges</Link> may apply depending upon how fast you were going, the court the ticket is in, and other factors.
    </div> is not eligible for reinstatement, we can also get you back on the road legally with a Limited Driving Privilege that will allow you to go back and forth to work. This will help you avoid getting more tickets for driving with a suspended license while you are waiting to reinstate your license.
    
 </div>},
  {key: 5, title: 'How many points will I have on my license if I plead guilty and pay my court fine for a suspended license ticket without hiring a suspended license ticket lawyer?', preview: <div>
    <div className="BodyGreyLeft mb16">You will get 12 points on your record and your license will be automatically suspended for 12 months. <strong>TicketTamer can get  your license reinstated for just $300 in most cases, and you won’t have to go to court.</strong> 
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    You will get 12 points on your record <i>and your license will be automatically suspended for 12 months - for each ticket you get for driving with a suspended license.</i>
    <br/>
    <br/>
    {/* <a href="https://dor.mo.gov/faq/driver-license/tickets-points.html" className="Link" target="_blank" rel="noreferrer">Click here for Missouri's point system.<ArrowRightOutlined /></a>
    <br/>
    <br/> */}
    You will also have to a lot more for insurance, and may have a hard time even getting it -­ for years. With most traffic tickets, insurance companies use tickets and points to increase your rates.
    <br/>
    <br/>
    {/* <a target="" href="https://www.nerdwallet.com/article/insurance/auto-insurance-rates-after-speeding-ticket" className="Link" target="_blank" rel="noreferrer">Click here for more information on tickets and insurance.<ArrowRightOutlined /></a>
    <br/>
    <br/> */}
    With a ticket for driving while suspended, you will get so many points that many insurers simply will not cover you, or will only cover you through a state-sponsored assia:ned risk pool.
    <br/>
    <br/>
    <strong>TicketTamer will help you avoid this by fixing your suspended license for just $300. This includes a $100 assessment fee to determine  what it will take to get your license  reinstated, a strategy session for getting you back on the road, guiding you through the process and filing your compliance documentation with the proper court.</strong>
    <br/>
    <br/>
    If your license is not eligible for reinstatement, we can also get you back on the road legally with a Limited Driving Privilege that will allow you to go back and forth to work. This will help you avoid getting more tickets for driving with a suspended license while you are waiting to reinstate your license.

    </div>
    
  </div>},
  {key: 6, title: 'Why should I hire a suspended license lawyer to fix my ticket for driving with a suspended license?', preview: <div>
    <div className="BodyGreyLeft mb16">Your driver's license will be suspended for one year if you just pay a suspended license ticket. <strong>TicketTamer can get  your license reinstated for just $300 in most cases, and you won’t have to go to court.  </strong>
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    Just paying your suspended license ticket is the worst thing you can do.
  <br/><br/>
  {/* <a href="https://dor.mo.gov/faq/driver-license/tickets-points.html" className="Link" target="_blank" rel="noreferrer">Click here for more on license suspension and revocation.<ArrowRightOutlined /></a>
  <br/><br/> */}
    You will get 12 points on your record <i>and your license will be automatically suspended for 12 months - for each ticket you get for driving with a suspended license.</i>
    <br/><br/>

    {/* <a href="https://dor.mo.gov/faq/driver-license/tickets-points.html" className="Link">Click here for Missouri's point system.<ArrowRightOutlined /></a>
    <br/><br/> */}
    You will also have to pay a lot more for insurance, and may have a hard time even getting it
    -- for years. With most traffic tickets, insurance companies use tickets and points to increase your rates.
    <br/><br/>

    {/* <a href="https://www.nerdwallet.com/article/insurance/auto-insurance-rates-after-speeding-ticket" className="Link" target="_blank" rel="noreferrer">Click here for more information on tickets and insurance.<ArrowRightOutlined /></a>
    <br/><br/> */}
    With a ticket for driving while suspended, you will get so many points that many insurers simply will not cover you, or will only cover you through a state-sponsored assi1:ned risk pool.
    <br/><br/>
    <strong>TicketTamer will help you avoid this by fixing your suspended license for just $300. This includes a $100 assessment fee to determine what it will take to get your license reinstated, a strategy session for getting you back on the road, guiding you through the process and filing your compliance documentation with the proper court.</strong>
    <br/><br/>
    If your license is not eligible for reinstatement, we can also get you back on the road legally with a Limited Driving Privilege that will allow you to go back and forth to work. This will help you avoid getting more tickets for driving with a suspended license while you are waiting to reinstate your license.

    </div>
    
  </div>},
  {key: 7, title: "What questions should you ask before you hire an attorney to fix your suspended driver's license ticket?", preview: <div>
    <div className="BodyGreyLeft mb16">Before hiring an attorney to fix a stop sign ticket, you should ask these five questions: Compare and see why 40 percent of TicketTamer cases are referrals or satisfied returning clients. 
    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    Before hiring an attorney to fix a stop sign ticket, you should ask these five questions:
    <br/>
    <br/>

    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    <strong>Have you fixed suspended license sign tickets in this court before?</strong>
    <br/>
    If the answer is yes, that's good, of course. If the answer is no, consider getting another lawyer who has worked in that court before. It may not make a difference, but then again it may and you don't want to take any chances. 
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    <strong>How much will it cost to fix my ticket?</strong>
    <br/>
    The cost for legal services to fix a suspended license ticket can vary on a number of factors, such as what the ticket is for and what court the ticket is in. A good lawyer should be able to give you an accurate quote quickly if you provide this information . If they don't, move on.
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    <strong>How will you help me through the license reinstatement process?</strong>
    <br/>
    Reinstating a suspended license often requires clients to do certain things, like retake a driving test. You want a lawyer who will guide you through the process, not leave you on your own to figure it out yourself.
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    <strong>How will you keep me informed of developments on my case?</strong>
    <br/>
    Good lawyers take special care to keep you updated on your case. Bad lawyers will leave you wondering what will go on, and will probably try to avoid this question by simply reassuring you that they will keep in touch with them. Ask the lawyer how he or she will do that?
    </Card>
    <Card style={{backgroundColor: '#f0f3f4', marginBottom: 8}}>
    <strong>What kind of support will I have if/ have to pay a fine?</strong>
    <br/>
    If the lawyer fee includes your fine, you won't need to worry about this question. If not, be sure to ask if they will help you make you get your fine paid correctly. A good lawyer will provide detailed instructions for payment and may be willing to pay it for you for a fee. 
    </Card>
    </div>
    
  </div>},
  {key: 8, title: 'How much does a suspended license ticket lawyer cost in Missouri?', preview: <div>
    <div className="BodyGreyLeft mb16">The cost of a speeding ticket lawyer in Missouri will vary depending upon a lot of factors, including the speed limit, how fast you were going, your driving record, and the court.
TicketTamer charges just $300 to fix a suspended license ticket in most cases, and you won't have to go to court. 

    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    The cost of a speeding ticket lawyer in Missouri will vary depending upon a lot of factors, including the speed limit, how fast you were going, your driving record, and the court.
    <br/><br/>
    TicketTamer charges just $300 to fix a suspended license ticket, although <Link to={Routes.PRICING}>other charges</Link> may apply.
    <br/><br/>
    Click here for the five questions you should ask before hiring a lawyer to fix your suspended license ticket

    </div>
    
  </div>},
  {key: 9, title: 'How much will my insurance rates increase if I plead guilty and pay my court fine for a suspended license ticket?', preview: <div>
    <div className="BodyGreyLeft mb16">Your insurance rates will increase hundreds, even thousands, of dollars if you just pay a suspended license ticket - if you can still get insurance at all. <strong>TicketTamer can prevent this from happening for just $300 in most cases, and you won't have to go to court. </strong>

    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    Your insurance rates will increase A LOT if you just pay a suspended license ticket- if your insurance company is even willing to continue providing you insurance. Here's why:
    <br/><br/>
    A suspended license ticket puts 12 points on your record, which leads to an automatic suspension of your driver's license for 12 months.
    <br/><br/>
    {/* <a href="https://dor.mo.gov/faq/driver-license/tickets-points.html" className="Link" target="_blank" rel="noreferrer">Click here for Missouri's point system.<ArrowRightOutlined /></a>
    <br/><br/> */}
    Insurers use a mere 2-point violation to increase their rates by hundreds, even thousands, of dollars. With 12 points, insurers are likely to view you as too risky to insure at all, or will only cover you through a state-sponsored assi ned risk pool.
    <br/><br/>
    Here is what we can say for certain: When you get a ticket, you will lose any good driver discount you may have, and be slapped with a surcharge for the ticket. The amount will vary, but in our experience, just one ticket will increase your rates hundreds, even thousands of dollars a year - for 3-5 years.
    {/* <br/><br/>
    <a href="https://www.nerdwallet.com/article/insurance/auto-insurance-rates-after-speeding-ticket" className="Link" target="_blank" rel="noreferrer">Click here for more information on tickets and insurance.<ArrowRightOutlined /></a> */}
    <br/><br/>
    <strong>TicketTamer can help you avoid this by negotiating with the prosecutor to reduce your ticket down to a non-moving or no-point violation. Our success rate is 97%, but we are more proud that 40% of our cases are referrals or satisfied returning clients. We know the law and the courts, and will get the charge reduced down to a non-moving or no point violation.</strong>

    </div>
    
  </div>},
  {key: 10, title: "How do I dismiss a suspended driver's license ticket in Missouri?", preview: <div>
    <div className="BodyGreyLeft mb16">You can go to court and ask the judge to dismiss it. He almost certainly won't because a suspended license ticket is a serious charge. <strong>TicketTamer can fix it for you for just $300 in most cases, and you won't have to go to court. </strong>

    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    You can go to court and ask the judge to dismiss it. He almost certainly won't because a suspended license ticket is a serious charge. 
    <br/><br/>
    <strong>TicketTamer can fix it for you for just $300, and you won’t have to go to court. This includes a $100 assessment fee to determine what it will take to get your license reinstated, a strategy session for getting you back on the road, guiding you through the process and filing your compliance documentation with the proper court.</strong>
    <br/><br/>
    If your license is not eligible for reinstatement, we can also get you back on the road legally with a Limited Driving Privilege that will allow you to go back and forth to work. This will help you avoid getting more tickets for driving with a suspended license while you are waiting to reinstate your license.

    </div>
    
  </div>},
  {key: 11, title: "How many points does a suspended driver's license ticket give you in Missouri?", preview: <div>
    <div className="BodyGreyLeft mb16">A suspended license ticket puts 12 points on your record, which leads to an automatic suspension of your driver's license for 12 months. <strong>TicketTamer can help you keep your license for just $300 in most cases, and you won't have to go to court. </strong>

    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    A suspended license ticket puts 12 points on your record, which leads to an automatic suspension of your driver's license for 12 months - for each driving while suspended ticket you get. It will stay on your record for three years.
    <br/><br/>
    {/* <a href="https://dor.mo.gov/faq/driver-license/tickets-points.html" className="Link">Click here for Missouri's point system.<ArrowRightOutlined /></a>
    <br/><br/> */}
    Insurers use a mere 2-point violation  to increase their  rates by hundreds, even  thousands, of dollars. With 12 points, insurers are likely to view you as too risky to insure at all, or will only cover you through a state-sponsored assigned risk pool.
    <br/><br/>
    {/* <a href="https://www.nerdwallet.com/article/insurance/auto-insurance-rates-after-speeding-ticket" className="Link" target="_blank" rel="noreferrer">Click here for more information on tickets and insurance.<ArrowRightOutlined /></a>
    <br/><br/> */}
    <strong>TicketTamer can help you avoid this by fixing your driving while suspended tickets for just</strong>
    $300, without your having to go to court. This includes a $100 assessment fee to determine what it will take to get your license  reinstated, a strategy session  for getting you back on the road, guiding you through the process and filing your compliance documentation with the proper court.
    <br/><br/>
    If your license is not eligible for reinstatement, we can also get you back on the road legally with a Limited Driving Privilege that will allow you to go back and forth to work. This will help you avoid getting more tickets for driving with a suspended license while you are waiting to reinstate your license.

    </div>
    
  </div>},
  {key: 12, title: "How long does suspended license ticket stay on record in Missouri?", preview: <div>
    <div className="BodyGreyLeft mb16">Your suspended license ticket will stay on your record forever. The ticket will put 12 points on your record, and they will stay on your driving record for 3 years. <strong>TicketTamer can keep your record clean for just $300 in most cases, and you won't have to go to court. </strong>

    </div>
  </div>, content: <div>
    <div className="BodyGreyLeft mb16">
    Your suspended license ticket will stay on your record forever.
    <br/><br/>
    The ticket will put 12 points on your record, and they will stay on your driving record for 3 years.
    <br/><br/>
    {/* <a href="https://dor.mo.gov/faq/driver-license/tickets-points.html" className="Link">Click here for Missouri's point system.<ArrowRightOutlined /></a>
    <br/><br/> */}
    Even when the points fall off of your record, the violation itself will stay on your record forever. Because the violation is still on your record, insurance companies may charge you higher rates for 3-5 years, depending upon the insurer and the state.
    <br/><br/>
    {/* <a href="https://www.nerdwallet.com/article/insurance/auto-insurance-rates-after-speeding-ticket" className="Link" target="_blank" rel="noreferrer">Click here for more information on tickets and insurance.<ArrowRightOutlined /></a>
    <br/><br/> */}
    <strong>TicketTamer can help you avoid this by fixing your suspended driver's license ticket for just $300, without your having to go to court. This includes a $100 assessment fee to determine what it will take to get your license reinstated, a strategy session for getting you back on the road, guiding you through the process and filing your compliance documentation with the proper court.</strong>
    <br/><br/>
    If your license is not eligible for reinstatement, we can also get you back on the road legally with a Limited Driving Privilege that will allow you to go back and forth to work. This will help you avoid getting more tickets for driving with a suspended license while you are waiting to reinstate your license.

    </div>
    
  </div>},
]


const SuspendedLicenseLawyers = (props) => {
  return <ServiceTemplateCascade 
  title="Suspended License Lawyers" 
  items={Items}
  image={suspendedImg}
  url="submit-suspended-license-ticket"
  metaDescription="Submit your suspended license ticket  - Get your quote instantly - Schedule your free consultation"
  />;
}

export default SuspendedLicenseLawyers;
